@import "../styles/var.scss";

.job-details {
  $this: &;

  &__wrapper {
    display: inline-grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    width: 100%;
  }

  &__title {
    font-weight: 500;
    color: $ab-mgrey;
    padding-bottom: 8px;
  }

  &__label {
    color: $ab-mgrey;
    padding-bottom: 4px;
  }
  &__body {
    font-size: 16px;
    color: $ab-black;
  }
  &__link.MuiTypography-colorPrimary {
    color: $ab-link;
  }

  &__wrapper {
    &--mt2 {
      margin-top: 16px;
    }
  }

  &__actions {
    width: calc(100% - 32px);
    background-color: white;
    padding: 16px;
    text-align: center;
  }

  &__actions--fixed {
    position: fixed;
    box-shadow: 0px -3px 4px -3px #9e9e9e;
    bottom: 0;
    max-width: calc(1280px - 32px);
  }

  &--mobile {
    #{$this}__wrapper {
      display: grid;
      grid-template-columns: 100%;
      row-gap: 16px;
    }
    #{$this}__group {
      display: inline-grid;
      grid-template-columns: 50% 50%;
    }

    #{$this}__label {
      // Reset padding as this lavel and body is in same line.
      padding-bottom: 0;
    }

    #{$this}__body {
      display: inline-grid;
      justify-items: end;
      align-items: center;
    }

    #{$this}__body--bold {
      font-weight: bold;
    }
  }

  &--mobile-wrap {
    #{$this}__wrapper {
      display: grid;
      grid-template-columns: 100%;
    }
    #{$this}__group:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.job {
  // Margin
  &__header,
  &__details,
  &__pro,
  &__payment,
  &__note {
    margin-left: 16px;
    margin-right: 16px;

    .header-3 {
      font-weight: $fontWeightMedium;
      font-size: 16px;
    }
  }

  &__header {
    display: inline-flex;
    justify-content: space-between;
    width: calc(100% - 32px);
    line-height: 24px;
  }

  &__border {
    border-bottom: 1px solid $ab-greyl;
    border-radius: 3px;
    padding: 16px;
    padding-left: 0;
  }

  &__border--light {
    border-color: #dbdbdf;
  }

  &__payment {
    background-color: #f9f8ff;
    border-color: $ab-primary;
  }

  // Note
  &__note {
    border-radius: 3px;
  }

  &__note--value {
    padding: 16px;
    background-color: #f9f8ff;
    border: 1px solid $ab-primary;
  }

  // Location
  &__details {
  }

  // Grid container
  &__container {
    display: inline-block;
    width: 100%;
  }

  // Button Danger
  &__cancel .button--danger {
    color: red;
    font-size: 16px;
  }
}

// Button
.job__note,
.job__pro,
.job__details,
.job__payment {
  .button {
    margin-top: 8px;

    border-width: 1px;
    font-weight: $fontWeightMedium;
  }

  .button--hyperlink {
    padding-left: 0;
    font-weight: $fontWeightMedium;
    display: inline-flex;
    align-items: center;
    border: none;
  }

  .button--contained {
    background-color: $ab-primary;
    color: white;
  }

  .button--contained:hover {
    background-color: #4843b9;
  }
}

.button__icon--right {
  margin-left: 4px;
}

// Open in new window size
.MuiSvgIcon-root.button__icon--right {
  width: 22px;
}
