@import "../styles/var.scss";

.manage-account {
  margin: auto;
  width: 70%;
  min-width: 600px;
  max-width: 800px;

  &--inline {
    margin-top: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .add-card-button {
    float: right;
  }

  .account-error {
    color: $ab-danger;
  }
}

@media only screen and (max-width: 600px) {
  .manage-account {
    min-width: auto;
    width: 100%;

    .add-card-button {
      margin-top: 8px;
      float: left;
    }

    // .inline-space-between {
    //   margin-top: 16px;
    //   flex-direction: column;
    //   align-items: flex-start;
    // }
  }
}
