@import "../styles/var.scss";

.job-actions {
  .button {
    white-space: nowrap;
    border-color: $ab-black;
    border-width: 1px;
    font-weight: $fontWeightMedium;
    margin-right: 8px;

    &--pro-resting {
      background-color: transparent;
    }

    &--mark-complete {
      background-color: $ab-primary;
      color: white;
      border-color: $ab-primary;
    }

    &--declined {
      background-color: $ab-danger;
      color: white;
      border-color: $ab-danger;
      cursor: default;
    }

    &--accepted {
      background-color: $ab-success;
      color: white;
      border-color: $ab-success;
      cursor: default;
    }
  }

  &--spacer {
    display: block;
    height: 100px;
  }

  // Material ui hovers
  .MuiButton-root.button--mark-complete:hover {
    background-color: darken($ab-primary, 15%);
    border-color: darken($ab-primary, 15%);
  }

  .MuiButton-root.button--declined:hover {
    background-color: $ab-danger;
    border-color: $ab-danger;
  }

  .MuiButton-root.button--accepted:hover {
    background-color: $ab-success;
    border-color: $ab-success;
  }

  // Last button should not have right margin;
  .button:last-child {
    margin-right: 0px;
  }
}
