.notification-settings__select {
  .MuiOutlinedInput-notchedOutline legend {
    width: 35px !important;
  }
}

.notification-settings__type {
  min-width: 100px;
}

.notification-settings__wrapper {
  display: inline-flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  padding: 0px 18px;
  align-items: center;
  min-height: 100px;
}
