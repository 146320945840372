@import "../styles/var.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.cleaning-img-dialog {
  position: relative;
  width: 100%;
  height: 100%;

  .MuiIconButton-root {
    position: absolute;
    z-index: 1;
    top: 16px;
    left: 16px;
    color: white;
  }

  .image-gallery-description {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba($ab-slate-900, 0.7);
    border-radius: 4px;
    font-size: 14px;
  }

  .image-gallery-svg {
    height: 60px;
    width: 30px;
  }

  &__container {
    background-color: $ab-slate-900;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.cleaning-imgs-carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  background-color: rgba($ab-slate-400, 0.5);
  padding: 10px;

  &__image-container {
    flex: 0 0 auto;
    margin: 0 4px;
    scroll-snap-align: start;
    transition: transform 0.2s ease-out;
    width: 150px;
    height: 150px; /* Ensure the container has a fixed height */
    border-radius: 8px;
    background-color: $ab-slate-900;
    overflow: hidden; /* Ensure overflow is hidden */
    position: relative; /* Position relative to center image */
  }

  &__image {
    cursor: pointer !important;
    width: 100%;
    height: 100%;
    object-fit: contain; /* Use contain to keep aspect ratio */
    object-position: center; /* Center the image */
    border-radius: 8px;
    display: block;
    margin: 0 auto;
  }
}
