@import '../../styles/var.scss';

.c-card {
  border-radius: $borderRadius;
  border: 1px solid lighten($shadow, 15%);
  padding: 24px 32px;

  &.card-elevated {
    box-shadow: 0px 4px 16px -8px $shadow;
    margin-bottom: 24px;
  }
}
