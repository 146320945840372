@import "../../styles/var.scss";

.promotion-dialog {
  // Reset modal
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
  }
  .MuiDialog-paper {
    overflow: initial;
  }
  .MuiDialogContent-root {
    overflow: initial;
  }

  .MuiDialogActions-root {
    justify-content: center;
    position: relative;
  }

  .MuiDialogContent-root {
    background-color: white;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 16px 24px;
    overflow: hidden;
  }
  .MuiDialogContent-root:first-child {
    padding-top: 16px;
  }
}

.promotion {
  position: relative;
  &__video {
    position: relative;
  }
  &__video iframe {
    position: absolute;
    z-index: 1;
  }
  &__video-container {
    width: 464px;
    height: 260px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiCircularProgress-colorPrimary {
      color: white;
    }
  }
  &__header {
    font-size: 20px;
    &.MuiTypography-h5 {
      margin-bottom: 8px;
      font-weight: $fontWeightMedium;
    }
  }
  &__round-button {
    width: 64px;
    height: 64px;
    &.MuiButtonBase-root {
      background-color: white;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      border-radius: 64px;
    }

    .MuiSvgIcon-root {
      font-size: 3em;
      color: #6059f7;
    }
  }
  &__content {
    color: #585858;
    max-width: 464px;
  }
}

.mobile-dialog {
  .promotion__video-container {
    width: 336px;
    height: 200px;
  }
}

.promotion-animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  &--fast {
    animation-duration: 800ms;
  }

  &--bounceInLeft {
    animation-name: bounceInLeft;
  }

  &--bounceInRight {
    animation-name: bounceInRight;
  }
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
