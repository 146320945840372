@import "./var.scss";
.body-text {
  font-size: 16px;
}

.subtitle {
  font-size: 14px;
  color: $ab-grey;
  font-weight: $fontWeightMedium;
}
