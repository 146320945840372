@import "./var.scss";

tr.table-row {
  &--started {
    background-color: $ab-slate-900;
    .MuiTableCell-body,
    .MuiIconButton-root {
      color: white;
    }
    .label,
    .grey {
      color: $ab-slate-400;
    }

    .link {
      color: lighten($ab-link, 10%);
    }

    .MuiButton-containedPrimary {
      background-color: white;
      color: $ab-slate-900;
    }
  }
  &--started.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: darken($ab-slate-900, 10%);
  }
}

// CARD
.jobs-card.jobs-card {
  &--started {
    background-color: $ab-slate-900;
    color: white;

    .label,
    .grey {
      color: $ab-slate-400;
    }

    .link {
      color: lighten($ab-link, 10%);
    }
  }
}

.jobs-card--started .jobs-card__content--secondary {
  color: $ab-slate-400;
}
