@import "../styles/var.scss";
// FORMS
// NEED TO REFACTOR THIS

.mobile-ui {
  .navigation {
    padding: 0;
    align-items: center;
  }
  .pms-signup {
    width: calc(100% - 32px);
    margin: 16px auto;
    margin-top: 32px;
  }

  .assignee__status--accepted {
    color: green;
  }
  .assignee__status--declined {
    color: red;
  }

  .pms-reservation {
    .wrapper__details {
      min-height: 232px;
      flex-direction: column;
      justify-content: space-between;
    }

    .cleaning__housekeeper {
      .inline-flex {
        min-height: 232px;
        flex-direction: column;
      }
    }
  }

  .pms-reservation__wrapper:last-child {
    margin-bottom: 100px;
  }

  .new-reservation {
    min-width: 95%;
  }

  .new-property {
    &__stepper {
      width: calc(100% - 16px);
      margin: 8px auto;
    }
  }

  .pms-property {
    &__content {
      width: calc(100% - 16px);
      margin: 8px;
      margin-top: 24px;
    }
    .content {
      padding: 24px 16px;
      width: initial;
    }

    .content__spacer {
      width: 100%;
    }
  }

  .housekeeper-cleaning {
    &.pt2 {
      padding: 0;
    }
  }

  .housekeeper-cleaning__guest {
    padding-bottom: 150px;
  }

  .housekeeper-cleaning__wrapper .inline-flex {
    flex-direction: column;
    min-height: 230px;
  }

  // DIALOGS
  .MuiDialog-paperWidthSm {
    width: 100%;
  }

  .MuiDialog-paper {
    margin: 8px;
    padding: 0 8px;
  }

  .pms-property__content {
    padding: 0;
  }

  .pms-dialog__body,
  .pms-dialog--small {
    min-width: 300px;
  }

  .MuiDialogTitle-root,
  .MuiDialogActions-root {
    padding-right: 0;
    padding-left: 8px;
    width: calc(100% - 8px);
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .MuiDialogContent-root {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.mobile-card {
  width: calc(100% - 24px);
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  border-radius: 3px;
  border: 1px solid #e7e7ef;
  background-color: white;
  margin-top: 16px;

  &.MuiPaper-elevation1 {
    box-shadow: 0px 8px 8px rgba(157, 157, 165, 0.3);
  }

  .MuiCardActionArea-root {
    padding: 12px 8px;
    min-height: 100px;
  }

  .quick-actions {
    background-color: #f1f1f7;
    padding: 8px 8px;
    border-top: 1px solid $ab-greyxl;

    .MuiButtonBase-root {
      background-color: white;
    }
  }

  &--host .MuiButtonBase-root {
    padding-bottom: 0;
    min-height: 172px;
  }

  .status {
    font-size: 13px;
  }

  label.read-only {
    display: block;
    font-size: 13px;
    color: $ab-grey;
    margin-bottom: 2px;
  }

  &__cleaning-date {
    display: inline-flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__property {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__channel {
    font-size: 14px;
    color: $ab-grey;
    font-weight: $fontWeightMedium;
    margin-top: 4px;
  }

  &__housekeeper {
    background-color: #f3f3f3;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: -12px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
  }

  &__maid-note {
    display: inline-flex;
    margin-bottom: 8px;

    p {
      width: 320px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
  }

  .cleaning {
  }

  &__housekeeper-footer {
    background-color: $ab-greyxl;
    border-top: 1px solid $ab-greyl;
    margin-left: -12px;
    padding-left: 12px;
    margin-right: -12px;
    padding-right: 12px;
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 32px;
  }

  .MuiTypography-overline {
    line-height: 2;
  }

  .cleaning__housekeeper {
    min-width: 200px;
  }

  .cleaning__note {
    font-size: 13px;
    margin-top: 3px;
    display: inline-flex;
  }

  .note__icon {
    margin-right: 2px;
    font-size: 15px;
  }

  .MuiCardActionArea-root {
    font-size: 16px;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
  }

  &__housekeeper-info {
    width: 100%;
    font-size: 14px;
  }

  &__icon.MuiSvgIcon-root {
    font-size: 18px;
    padding-right: 4px;
    color: #727272;
  }
}

.mobile-ui {
  .pms-reservation.pt4 {
    padding-top: 0;
  }

  .pms-reservation__reservation-details {
    display: block;
  }
}

@media only screen and (max-width: 639px) {
  .mobile-pt1 {
    padding-top: 8px;
  }
  .mobile-pb1 {
    padding-bottom: 8px;
  }
  .mobile-pt2 {
    padding-top: 16px;
  }
  .mobile-pb2 {
    padding-bottom: 16px;
  }
  .mobile-pt3 {
    padding-top: 24px;
  }
  .mobile-pb3 {
    padding-bottom: 24px;
  }

  //Fix for Sendbird attach file layer

  .sendbird-fileviewer__header__left__filename {
    display: none !important;
  }


}
