@import "../styles/var.scss";

input:-internal-autofill-selected {
  background: transparent !important;
}

.subscribe {
  background-color: $background;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .c-card {
    background: white;
    width: 500px;
  }

  .subscribe-form {
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: -24px;

    padding: 24px 32px;
    padding-top: 0;
  }

  .subscribe-header {
    font-weight: $fontWeightMedium;
    font-size: 24px;
  }

  .subscribe-subheader {
    font-weight: $fontWeightRegular;
    font-size: 16px;
  }

  .subscribe-info {
    display: flex;
    flex-direction: row;
  }

  .subscribe-info .info {
    margin-right: 48px;
  }

  .info-title {
    font-size: 48px;
    font-weight: $fontWeightRegular;
    margin-bottom: 8px;
  }

  .info-small {
    font-size: 24px;
    line-height: 48px;
  }

  .info-label {
    font-size: 16px;
    color: $ab-greyl;
  }

  .subscribe-payment {
    margin-top: 24px;
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 24px;
    padding-bottom: 24px;
    background: rgba($ab-host, 0.07);
    border-top: 1px solid rgba($ab-host, 0.1);
    border-bottom: 1px solid rgba($ab-host, 0.1);
  }

  .payment-header {
    font-weight: $fontWeightBold;
  }

  .payment-message {
    padding-bottom: 8px;
  }

  .payment-important {
    font-size: 14px;
    color: rgba(black, 0.5);
  }

  .subscribe-info .info:last-child {
    margin: 0;
  }

  .input-label {
    border-bottom: 1px solid rgba($ab-host, 0.3);
    padding: 24px 0;
  }

  .StripeElement {
    margin-bottom: 24px;
    padding: 24px 0;
    border-bottom: 1px solid rgba($ab-host, 0.3);
  }

  .input-text {
    width: 440px;
    background: transparent;
    outline: none;
    padding: 24px 0;
    border: none;
    margin-left: 8px;
    font-size: 14px;
  }

  .small {
    font-size: 12px;
  }

  .success-body {
    margin-top: 8px;
    margin-bottom: 24px;
  }
}

.payment-method {
  background-color: $background;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .c-card {
    background: white;
    width: 500px;
  }

  .subscribe-form {
    background: rgba($ab-host, 0.05);
    border-top: 1px solid rgba($ab-host, 0.2);
    margin-left: -32px;
    margin-right: -32px;
    margin-bottom: -24px;

    padding: 24px 32px;
    padding-top: 0;
  }

  .header-3.trial {
    margin-bottom: 0;
  }

  .input-label {
    border-bottom: 1px solid rgba($ab-host, 0.3);
    padding: 16px 0;
  }

  .StripeElement {
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 16px 0;
    border-bottom: 1px solid rgba($ab-host, 0.3);
  }

  .input-text {
    width: 440px;
    background: transparent;
    outline: none;
    padding: 16px 0;
    border: none;
    margin-left: 8px;
    font-size: 14px;
  }

  .small {
    font-size: 10px;
    font-style: italic;
  }
  .success-body {
    margin-top: 8px;
    margin-bottom: 24px;
  }
}

.text-danger {
  color: $ab-danger;
  margin-top: 8px;
  font-size: 14px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 500px) {
  .payment-method {
    .c-card {
      width: calc(100% - 72px);
    }
    .input-text {
      width: calc(100% - 52px);
    }
  }
}

@media only screen and (max-width: 500px) {
  .subscribe {
    .c-card {
      width: calc(100% - 72px);
    }
    .input-text {
      width: calc(100% - 52px);
    }
  }
}

@media only screen and (max-width: 340px) {
  .subscribe {
    .subscribed-action {
      flex-direction: column;
    }
  }
}
