@import "../styles/var.scss";

.jobs-card {
  position: relative;
  padding: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 8px;
  padding-top: 12px;
  background-color: white;
  border: 1px solid lighten($ab-greyl, 8%);
  margin-bottom: 16px;

  &__pros-assigned {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  &__title {
    font-size: 0.8rem;
  }

  &__content--secondary {
    color: $ab-grey;
  }

  &__location-icon {
    margin-right: 4px;
    opacity: 0.5;
  }

  &__location {
    font-size: 0.8rem;
    margin-top: 4px;
  }

  &__location--mobile {
    color: black;
    padding: 6px 12px;
    border-radius: 12px;
  }

  &__date {
    font-size: 1em;
    font-weight: $fontWeightMedium;
    margin-bottom: 4px;
  }

  &__date--split {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  &__pros-assigned {
    .status,
    .label {
      font-size: 0.8rem;
    }
  }

  .pros-assigned {
    &__category {
      font-size: 0.8rem;
      color: $ab-grey;
    }
    &__name {
      font-weight: $fontWeightRegular;
    }
  }

  .card__float {
    opacity: 0.8;
  }
}

.jobs__filters {
  padding-left: 16px;
}

.my-pros {
  margin-left: 8px;
  margin-right: 8px;
  padding: 8px;
  border-bottom: 1px solid $ab-greyl;
  border-radius: 3px;
  background-color: white;
  margin-bottom: 12px;
  &__title.header-3 {
    margin-bottom: 4px;
  }

  &__category {
    margin-bottom: 4px;
  }

  &__contact-info {
  }
}

.discrepancies-action {
  display: inline-flex;
  justify-content: space-between;
  width: calc(100% - 24px);
  padding-right: 16px;
  align-items: center;

  // Reset margin for item account so the two elements are center aligned
  .items-count.mb1 {
    margin-bottom: 0;
  }

  // Change button label styling
  .MuiButton-label {
    color: $ab-link;
    font-weight: normal;
  }
}

@media only screen and (max-width: 500px) {
}
