@import "basscss-sass/scss/_grid";
@import "basscss-sass/scss/_margin";
@import "basscss-sass/scss/_padding";

@import "reset.scss";
@import "var.scss";
@import "roles.scss";
@import "font.scss";
@import "flexbox.scss";
@import "shadow.scss";

// Custom
@import "forms.scss";
@import "button.scss";
@import "table.scss";
@import "icon.scss";
@import "mobile.scss";
@import "typography.scss";
@import "miscellaneous.scss";
@import "dialogs.scss";
@import "desktopPages.scss";
@import "showViewDetails.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;
