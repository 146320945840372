@import "../styles/var.scss";

.clients-property {
    &__hero {
        background-color: $ab-greyxl;
        padding: 16px;
    }
    
    &__meta {
        margin-top: 16px;
        margin-left: 48px;
    }

    &__label {
        font-size: 14px;
        color: $ab-mgrey;
    }
}