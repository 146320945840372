.new-features {
  width: calc(100% - 16px);
  padding: 8px;
  background: #ffed90;
  color: #292103;
  font-size: 15px;
  text-align: center;
  line-height: 18px;
  z-index: 3;
  position: relative;

  .link {
    color: #292103;
    text-decoration: underline;
  }
}

.mobile-ui {
  .new-features {
    font-size: 13px;
  }
}
