@import "../styles/var.scss";

.ab-dialog {
  .mb2 {
    margin-bottom: 16px !important;
  }

  &__body {
    min-width: 350px;
  }

  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.client-modal {
  &__checkbox.MuiFormControl-root {
    background-color: #f6fcff;
    // line-height: 120%;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #92b0c1;

    .MuiButtonBase-root {
      padding-top: 0;
    }

    .MuiFormControlLabel-label {
      line-height: 120%;
    }

    .MuiTypography-colorPrimary {
      color: $ab-link;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}
