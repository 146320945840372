@import "../styles/var.scss";

.cleaning-signup {
  margin: 0 16px;
  .form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 32px;
    .form {
      width: 400px;
    }
  }
}

.pms-signup {
  width: 724px;
  margin: 0 auto;
  height: calc(100% - 56px);
  display: flex;
  flex-direction: row;
  .form {
    width: 400px;
  }

  .form-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .signup-brand {
    flex: 1;
    &__price {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .brand {
    margin-top: 24px;
    font-size: 20px;
  }

  .price {
    font-size: 16px;
    &__superscript {
      position: relative;
      top: -18px;
      font-size: 24px;
      font-weight: $fontWeightMedium;
      opacity: 0.7;
    }
    &__number {
      font-size: 50px;
      color: #6059f7;
      font-weight: $fontWeightMedium;
    }
    &__time {
      opacity: 0.7;
      font-weight: $fontWeightMedium;
    }
  }
}

.mobile-ui {
  .pms-signup {
    flex-direction: column;
    .signup-brand {
      flex: 0;
      padding: 0;
    }

    .signup-brand__content {
      text-align: center;
    }
  }
}


.new-signup {
  .login-cta {
    text-align: center; 
    margin-top: -50px;
    margin-bottom: 50px;
  }
}
