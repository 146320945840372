.jobDetails {
  background-color: #f8f8f8;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  width: calc(100% - 32px);
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;

  &__list {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 2px;
  }
}
