.notification-settings {
  padding: 24px;

  &__select {
    min-width: 100px;
  }

  &__list li {
    border-bottom: 1px solid #ababab;
    padding-bottom: 8px;
    margin-top: 16px;
  }

  .list {
    &__content {
      width: calc(100% - 162px);
      margin-right: 32px;
    }
    &__type {
      width: 72px;
    }
    &__action {
      width: 64px;
      margin-right: 8px;
    }
  }

  &__up-sell {
    background-color: #fff9d7;
    border: 1px solid #ffe343;
    border-radius: 4px;
    padding: 8px;
    width: 360px;
    margin: 0 auto;
    margin-bottom: 16px;
    p {
      line-height: 130%;
    }

    a {
      color: black;
    }
  }
}
