$background: #f6f6f6;

$shadow: #afb9cf;
$formLabel: #5a5a5a;
$hostShadow: rgba(96, 89, 247, 0.11);
$marketingColor: #00bcd4;
$buttonShadow: #afb9cf;

$ab-grey: rgba(129, 129, 137, 1);
$ab-mgrey: rgba(88, 88, 98, 1);
$ab-greyxl: rgba(231, 231, 239, 1);
$ab-greyxxl: rgba(249, 249, 253, 1);
$ab-greyl: rgba(185, 185, 193, 1);
$ab-black: rgba(21, 21, 23, 1);
$ab-white: rgba(255, 255, 255, 1);

$ab-host: rgba(66, 0, 255, 1);
$ab-ops: #1d2939;
$ab-housekeeper: rgba(255, 97, 92, 1);

$ab-link: rgba(58, 102, 255, 1);
$ab-danger: rgba(255, 41, 41, 1);
$ab-success: rgba(54, 181, 22, 1);

$borderRadius: 3px;
$navTotalHeight: 40px;

$housekeeperShadow: rgba($ab-housekeeper, 0.11);

// New definitions
$ab-primary: rgba(96, 89, 247, 1);

$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;

$ab-yellow-soft-bg: rgba(255, 243, 224, 1);
$ab-yellow-bg: rgba(255, 204, 128, 1);

// Tailwind colors
$ab-slate-900: #0f172a;
$ab-slate-400: #d9d9d9;

$ab-brand: #4200ff;
$ab-brand-light: #8793ff;
