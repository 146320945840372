@import "../styles/var.scss";

.pro-avatar__border {
        border: 3px solid;
        &--success {
            border-color: $ab-success;
        }

        &--danger {
            border-color: $ab-danger;
        }

        &--completed {
            border-color: $ab-primary;
        }

        &--grey {
            border-color: $ab-grey;
        }
    }
.pro-assigned__th {
    // &.MuiTableCell-root {
    //     padding: 8px;
    // }
}

.MuiAvatarGroup-avatar.MuiAvatar-colorDefault.pro-avatar__border {
    border: 3px solid;
    &--success {
        border-color: $ab-success;
    }

    &--danger {
        border-color: $ab-danger;
    }

    &--completed {
        border-color: $ab-primary;
    }

    &--grey {
        border-color: $ab-grey;
    }
}

.assigned-pros-table {
    &.MuiTable-root {
        margin-left: -16px;
        width: calc(100% + 32px);
    }
}