@import "./var.scss";

// Rename this file to => page.scss

.page-wrapper {
  $this: &;

  width: 100%;
  background-color: $ab-greyxxl;
  // Subtract height of navigation
  height: calc(100vh - #{$navTotalHeight});

  &.new-signup {
    height: auto;
  }

  &--split {
    background-color: white;
    display: inline-flex;
    justify-content: initial;
    height: 100vh;
  }

  &__tabs {
    height: 48px;
    width: 100%;
    box-shadow: 0px 2px 9px -3px $ab-greyl;
    z-index: 1;
    position: relative;

    // Mui-tabs
    .MuiTabs-root {
      max-width: 1278px;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__tabs--white {
    background-color: white;
  }
}

.page-content {
  // Account for border right and left
  max-width: 1278px;
  width: 100%;
  min-height: calc(100vh - #{$navTotalHeight});
  background-color: white;
  border-left: 1px solid $ab-greyxl;
  border-right: 1px solid $ab-greyxl;
  position: relative;

  margin: 0 auto;

  // Switch data based on
  &__jobs-tabs {
    border-bottom: 1px solid $ab-greyxl;
    margin-bottom: 16px;
  }

  &__tab--todo {
    min-width: 105px !important;
  }
}

.split-content {
  width: 50%;
  height: 100%;
  background-color: white;
  &--brand {
    background-color: #1f28eb;
    width: 50%;
  }

  &--centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 900px) {
  .split-content {
    width: 60%;

    &--brand {
      width: 40%;
    }
  }
}
@media only screen and (max-width: 800px) {
  .page-wrapper--split {
    // On smalled screens split wrapper will be stacked above the content.
    // To prevent contnet to overlay on top of the brand changed display to block
    display: block;
  }
  .split-content {
    width: 100%;
    height: initial;
    padding-top: 32px;

    &--brand {
      width: 100%;
      padding-top: 0;
    }
  }
}
