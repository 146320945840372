@import "../styles/var.scss";
.cleaning-status {
  .status {
    font-size: 14px;
    &--success {
      color: #36b516;
      font-weight: $fontWeightMedium;
    }
    &--danger {
      color: #ff2929;
      font-weight: $fontWeightMedium;
    }
    &--grey {
      color: #818189;
    }

    &--orange {
      color: #9a7800;
    }
    &--started {
      color: $ab-brand-light;
    }
  }
}
