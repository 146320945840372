.table--max-height-64 {
  max-height: 64vh;
}

.abnb-table {
  .MuiTableCell-root.MuiTableCell-head {
    background-color: #e7e7ef;
    border-top: 1px solid $ab-greyl;
    border-bottom: 1px solid $ab-greyl;
    padding-top: 6px;
    padding-bottom: 6px;
    color: $ab-grey;
  }

  // Padding left 32px aligns all the content on the left.
  .MuiTableCell-head:first-child {
    padding-left: 32px;
  }

  .MuiTableCell-body:first-child {
    padding-left: 32px;
  }
}

.table {
  &__nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__nowrap--location {
    width: 300px;
  }

  .MuiTableCell-root:first-child {
    padding-left: 32px;
  }

}

@media only screen and (max-width: 980px) {
  .table {
    &__nowrap--location {
      width: 150px;
    }

    .MuiTableCell-root:first-child {
      padding-left: 16px;
    }
  }
}
