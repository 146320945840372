@import "../styles/var.scss";

.button-group {
  display: inline-flex;
  align-items: center;

  .button:first-child {
    margin-right: 8px;
  }
}

.button-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.MuiTabs-root {
  &.tab-brand--ops {
    .MuiTab-textColorInherit.Mui-selected {
      color: #FFCC80 !important;
    }
    .MuiTabs-indicator {
      background-color: #FFCC80 !important;
    }
  }
}

.MuiButton-root {
  &.btn-primary {
    background-color: $ab-black;
    color: white;

    &:hover {
      background-color: lighten($ab-black, 10%);
    }

    &--outlined {
      border-width: 2px;
      border-color: $ab-black;
    }
  }

  &.btn-danger {
    background-color: $ab-danger;
    color: white;
    &:hover {
      background-color: darken($ab-danger, 20%);
    }
  }

  &.btn-brand {
    &--ops {
      background-color: darken(#FFCC80, 10%);
      color: #212121;
    }

    &--ops:hover {
      background-color: #FFCC80;
    }
  }
}

.button {
  -webkit-appearance: none;
  background: inherit;
  border: inherit;
  cursor: pointer;
  font-size: 14px;
  color: $ab-black;
  padding: 12px 24px;
  line-height: 16px;
  border-radius: $borderRadius;
  transition: background-color 0.25s ease-in-out;
  outline: none;

  // Using this class for Link's
  text-decoration: none;

  &.shadow {
    transition: box-shadow 0.35s ease;
    &:hover {
      box-shadow: 0px 0px 5px 0px $buttonShadow;
    }
  }

  &.button-shadow {
    box-shadow: 0px 0px 3px 0px #4e4e4e;
  }

  &.button-rounded {
    border-radius: 50px;
  }

  &.button-primary {
    color: white;
    background: #0087ff;
  }

  &.button-primary:disabled {
    opacity: 0.5;
  }

  &.button-white {
    background: white;
    box-shadow: 0px 0px 2px 0px grey;
  }

  &.button-secondary {
    background: grey;
    color: black;
  }

  &.button-green {
    background: #17b317;
    color: white;
  }

  &.button-marketing {
    background: $marketingColor;
    color: white;
  }

  &.button-mini {
    padding: 8px 12px;
  }

  &.button-large {
    font-size: 17px;
    padding: 18px 32px;
  }

  &.button-full {
    width: 100%;
  }

  &.button-host {
    background: $ab-host;
    color: white;
  }

  &.button-housekeeper {
    background: $ab-housekeeper;
    color: white;
  }

  &.button-dark {
    background: $ab-black;
    color: white;
  }

  &.button-danger {
    background-color: $ab-danger;
    color: white;

    &:hover {
      background-color: darken($ab-danger, 20%);
    }

    &.button-ghost {
      background-color: transparent;
      color: $ab-danger;
      border: none;
    }

    &.button-outlined {
      background-color: transparent;
      color: $ab-danger;
      border: 1px solid $ab-danger;
    }
  }

  &.button-menu {
    background: transparent;
    border: none;
    color: white;
    font-size: 20px;
    padding: 0;
  }

  &:disabled {
    cursor: default;

    &.button-host {
      background: rgba($ab-host, 0.25);
      color: rgba(white, 0.5);

      .button-progress {
        color: $ab-host;
      }
    }

    &.button-green {
      background: rgba(#17b317, 0.25);
      color: rgba(white, 0.5);
    }

    &.button-green.button-progress {
      color: #17b317;
    }

    &.button-marketing {
      background: rgba($marketingColor, 0.25);
      color: rgba(white, 0.5);

      .button-progress {
        color: $marketingColor;
      }
    }

    &.button-housekeeper {
      background: rgba($ab-housekeeper, 0.25);
      color: rgba(white, 0.5);

      .button-progress {
        color: $ab-housekeeper;
      }
    }
  }

  &.button-ghost {
    &.button-host {
      background: transparent;
      border: 1px solid $ab-host;
      color: $ab-host;
    }
  }
}

// NEW BUTTONS
.MuiButton-root.abnb-button {
  &--white {
    color: white;
    border-color: white;
  }
}
