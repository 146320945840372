.drop-shadow-1 {
  box-shadow: 1px 1px 4px 0px $shadow;

  &.shadow-host {
    box-shadow: 1px 1px 4px 0px $hostShadow;
  }

  &.shadow-housekeeper {
    box-shadow: 1px 1px 4px 0px $housekeeperShadow;
  }
}

.drop-shadow-2 {
  box-shadow: 1px 1px 8px 1px $shadow;

  &.shadow-host {
    box-shadow: 1px 1px 8px 1px $hostShadow;
  }

  &.shadow-housekeeper {
    box-shadow: 1px 1px 8px 1px $housekeeperShadow;
  }
}

.drop-shadow-3 {
  box-shadow: 1px 1px 16px 2px $shadow;

  &.shadow-host {
    box-shadow: 1px 1px 16px 2px $hostShadow;
  }

  &.shadow-housekeeper {
    box-shadow: 1px 1px 16px 2px $housekeeperShadow;
  }
}

.dark-host-shadow-3 {
  box-shadow: 1px 1px 16px 2px darken($ab-host, 10%);
}
