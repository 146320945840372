.pms-property {
  background-color: #efefef;
  height: calc(100% - 96px);
  padding-top: 16px;

  display: flex;
  flex-direction: row;

  &__sidebar {
    background-color: white;
    border-right: 1px solid #cecece;
    .MuiTab-textColorInherit {
      white-space: nowrap;
    }
  }

  &__content {
    width: 100%;
    overflow-y: auto;
  }

  .content {
    padding: 32px 40px;
    width: calc(100% - 80px);

    &__spacer {
      width: 80%;
    }

    &--white {
      background-color: white;
    }

    // temp: need to remove from typography class. Requires more work.
    .header-2,
    .header-3 {
      margin-bottom: 0;
    }
  }
}

.page-content__body {
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
}

@media only screen and (max-width: 600px) {
  .pms-property {
    flex-direction: column;
  }
}
