.pros-dialog {
  .MuiDialogContent-root,
  .MuiDialogTitle-root {
    padding-left: 12px;
    padding-bottom: 8px;
  }
  .MuiTypography-h6 {
    font-size: 1em;
    padding-left: 0;
  }
  .MuiDialogContent-root {
    padding-top: 0;
  }
  &__content {
    width: 400px;
    padding: 16px;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: flex-start;
  }

  &__feedback {
    margin-top: 24px;
  }

  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .pros-dialog {
    &__content {
      width: auto;
    }
  }
}
