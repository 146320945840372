@import "../styles/var.scss";
.job-flip {
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: darken($ab-danger, 10%);
  border: 2px solid $ab-danger;

  &__icon.MuiSvgIcon-root {
    margin-right: 4px;
    padding: 2px;
    background-color: green;
    background-color: darken($ab-danger, 20%);
    color: white;
    border-radius: 30px;
    font-size: 18px;
  }

  &__label {
    padding-right: 8px;
    color: white;
    font-weight: 500;
    font-size: 12px;
  }

  &--condenced {
    border: none;
    margin-left: 8px;
  }
}

.job-flip--condenced .job-flip__icon {
  margin-right: 0;
}

@media only screen and (max-width: 500px) {
  .job-flip {
    border-width: 1px;
    &__icon.MuiSvgIcon-root {
      font-size: 14px;
      margin-right: 2px;
    }
    &__label {
      font-size: 10px;
    }
  }
}
