.payment-reminder {
  position: fixed;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  background: #f5d9d9;
  border: 1px solid #ff2929;
  border-radius: 3px;
  color: black;
  padding: 4px 16px;
  min-width: 380px;
  bottom: 50px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 500px) {
  .payment-reminder {
    white-space: nowrap;
    width: 90%;
    bottom: 90px;
  }
}
