@import "../styles/var.scss";

.channel-list-col {

  border-right: 1px solid $ab-greyxl;

  & .channel-list-item {

    &:hover {
      cursor: pointer;
      background-color: $ab-yellow-soft-bg;
    }

    & > .unread-messages {
      border-radius: 5px;
      background-color: rgba(255, 204, 128, 1);
      color: $ab-black;
    }

    &.active {
      background-color: $ab-yellow-soft-bg;
      & > div {
        font-weight: bold;
      }
      & > .unread-messages {
        border-radius: 5px;
        background-color: rgba(255, 204, 128, 1);
        color: $ab-black;
      }
    }
  }

  & .channel-type {
    font-size: 12px;
    font-weight: 500;
    color: $ab-mgrey;
  }

  & .unread-messages {
    font-size: 12px;
    color: $ab-greyl;

  }
}

.channel-content-col {

  &.is_mobile.chat_list_open {
    display: none;
  }

  .sendbird-conversation {

    .sendbird-conversation__messages .sendbird-conversation__messages-padding {
      max-width: 100%;
    }

    .sendbird-thumbnail-message-item-body {
      min-width: 200px !important;
      max-height: 200px !important;
    }

    .sendbird-theme--light & {
      background-color: $ab-greyxxl !important;
    }
    .sendbird-conversation__messages  {
      .sendbird-message-content-reactions {
        background-color: $ab-white !important;
      }
      .sendbird-text-message-item-body.outgoing, .sendbird-message-content-reactions.primary {
        background-color: rgba(225, 245, 254, 1) !important;
        color: $ab-black;
      }
      .sendbird-text-message-item-body.incoming {
        background-color: $ab-white !important;
        color: $ab-black;
      }

      .sendbird-conversation__scroll-bottom-button {
        svg > path {
          fill: rgba(0,0,0, 0.80) !important;
        }
      }
    }

    .sendbird-msg-hoc {
      svg > path {
        fill: rgba(0,0,0, 0.80) !important;
      }
    }
    .sendbird-voice-message-input {
      .sendbird-progress-bar.sendbird-voice-message-input__indicator__progress-bar__bar.progress-bar-color--primary {
        background-color: #e1f5fe !important;
      }
      span.sendbird-label.sendbird-label--caption-2.sendbird-label--color-oncontent-1, .sendbird-label.sendbird-label--button-1.sendbird-label--color-primary {
        color: $ab-grey !important;
      }

      .sendbird-voice-message-input__controler__submit{
        background-color: $ab-greyxl !important;
        border-radius: 50% !important;
        &:hover {
          box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(222, 222, 231, 1) !important;
        }

        & svg, & svg > path {
          fill: rgba(0,0,0, 0.56) !important;
        }
      }

    }
    .sendbird-progress-bar.sendbird-voice-message-item-body__progress-bar.progress-bar-color--primary {
      background-color: #e1f5fe !important;
    }
    .sendbird-theme--light.sendbird-voice-message-item-body__status-button__button {
      background-color: $ab-greyxl !important;
    }
    .sendbird-voice-message-item-body__status-button__button__pause__inner {
      background-color: $ab-grey !important;
    }
    span.sendbird-label.sendbird-label--body-1.sendbird-label--color-oncontent-1 {
      color: $ab-grey !important;
    }
    .sendbird-message-content.incoming .sendbird-message-content__middle .sendbird-message-content__middle__sender-name {
      overflow: hidden !important;
    }
    .sendbird-message-input {
      .sendbird-message-input--textarea {
        background-color: #ffffff !important;
        border-radius: 5px;
        box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(222, 222, 231, 1) !important;
        overflow-y: auto !important;
        min-height: 60px !important;
      }
      & > button {
        background-color:$ab-greyxl !important;
        border-radius: 50% !important;
        &:hover {
          box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(222, 222, 231, 1) !important;
        }

        & svg, & svg > path {
          fill: rgba(0,0,0, 0.56) !important;
        }
      }
    }
  }
}
