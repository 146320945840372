.show-view {
  $this: &;

  &__wrapper {
    display: inline-grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    width: 100%;
  }

  &__title {
    font-weight: 500;
    color: $ab-mgrey;
    padding-bottom: 8px;
  }

  &__label {
    color: $ab-mgrey;
    padding-bottom: 4px;
  }
  &__body {
    font-size: 16px;
    color: $ab-black;
  }
  &__link.MuiTypography-colorPrimary {
    color: $ab-link;
  }

  &__wrapper {
    &--mt2 {
      margin-top: 16px;
    }
  }

  &__actions {
    width: calc(100% - 32px);
    background-color: white;
    padding: 16px;
    text-align: center;
  }

  &__actions--fixed {
    position: fixed;
    box-shadow: 0px -3px 4px -3px #9e9e9e;
    bottom: 0;
    max-width: calc(1280px - 32px);
  }

  &--mobile {
    #{$this}__wrapper {
      display: grid;
      grid-template-columns: 100%;
      row-gap: 16px;
    }

    #{$this}__wrapper--nowrap {
      #{$this}__group {
        display: inline-grid;
        grid-template-columns: 50% 50%;
      }
    }

    #{$this}__label {
      // Reset padding as this lavel and body is in same line.
      padding-bottom: 0;
    }

    #{$this}__body {
      display: inline-grid;
      justify-items: end;
      align-items: center;
    }

    #{$this}__body--bold {
      font-weight: bold;
    }
  }

  &--mobile-wrap {
    #{$this}__wrapper {
      display: grid;
      grid-template-columns: 100%;
    }
    #{$this}__group:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Creates a 2-column layout
  gap: 10px; // Adds gaps between both rows and columns
  row-gap: 20px; // Specifically adds more space between rows
}

@media only screen and (min-width: 500px) {
  .inline-label-body {
    display: grid;

    &__label,
    &__body {
      width: 100%;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .inline-label-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;

    &__body {
      word-break: break-word;
    }
  }
}
