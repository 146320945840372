@import "./var.scss";

$abPrimary: #6059f7;

html {
  overflow-y: auto;
}

html,
body {
  height: 100%;
  line-height: 120%;
  overflow-x: hidden;
  color: #212121;
  background-color: #f7f7fb;
}

p {
  line-height: 20px;
}

.ordered-list {
  list-style: inside;
  list-style-type: decimal;
  li {
    display: list-item;
    list-style-position: inside;
    margin-bottom: 4px;
  }
}

.marketplace-promo {
  max-width: 60%;
  margin: 0 auto;
  margin-bottom: 32px;
  background-color: #fffbf5;
  border: 1px solid #fbd8a4;
  border-radius: 8px;
  padding: 24px 16px;
}

.line-separator {
  height: 1px;
  border: none;
  background-color: $ab-greyl;
  margin-bottom: 16px;
  width: 90%;
  text-align: center;
}

.grid-container {
  display: inline-block;
  width: 100%;
}

button,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white\0 !important;
}

// Items count: Showing {#} of {#}
.items-count {
  padding-left: 32px;
}

// Avatars
.avatar {
  &--32 {
    width: 32px;
    height: 32px;
  }

  &--round {
    border-radius: 32px;
  }
}

// Row Column gap
.rc-gap--xs {
  column-gap: 3px;
  row-gap: 3px;
}

//DIALOG
[class*="MuiBackdrop-root"] {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.main {
  background-color: #f7f7fb;

  &.public {
    padding-bottom: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.gxp-layout {
  background-color: #f8f8fb;
}

.light-line {
  opacity: 0.6;
}

.relative {
  position: relative;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100vh;
}

.half-width {
  width: 50%;
}

.full-page {
  width: 100%;
  height: 100%;
}

.danger-color {
  color: $ab-danger;
}

.no-line-through {
  text-decoration: none;
}

.text-right {
  text-align: right;
}

.line {
  opacity: 0.4;
}

.text-nowrap {
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}

.card {
  padding: 32px 24px;
  background: white;
  border-radius: $borderRadius;
  border: 1px solid $ab-greyxl;

  &--in-wrapper {
    padding: 16px;
    background-color: white;
    border-radius: $borderRadius;
  }

  &--border {
    border: 1px solid $ab-greyl;
  }

  &--border-light {
    border: 1px solid #dbdbdf;
  }
}

.intercom-spacer {
  display: block;
  height: 130px;
}

.text-center {
  text-align: center;
}

.inline {
  display: inline-flex;
  align-items: center;
}

.align-top {
  align-items: flex-start;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.pending {
  color: #aaaaaa;
}

.color-grey {
  color: $ab-grey;
}

//Drawer color
.dark-drawer > div:last-child {
  background: $ab-black;
  color: white;
}

.dark-drawer .user-menu .link {
  color: white;
}

.nav-link {
  line-height: normal;
  padding: 0 16px;
}

.text-wrap {
  word-break: break-all;
}

.link.logo {
  color: lighten(black, 20%);
  font-weight: $fontWeightBold;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: $ab-link;
  line-height: 20px;
}

.body-line {
  line-height: 22px;
}

.black {
  color: $ab-black;
}

.logo-font {
  font-family: "Comfortaa", cursive;
}

.small {
  font-size: 14px;
}

.MuiButton-root.negative-8 {
  margin-left: -8px;
}

.grey {
  color: #757575;
}

.italic {
  font-style: italic;
}

.link.active {
  cursor: default;
}

.label {
  font-size: 14px;
  color: #757575;
  display: block;
  &--read-only {
    margin-bottom: 8px;
  }
}

.page-header {
  font-size: 24px;
}

.header-1 {
  font-size: 40px;
  margin-bottom: 24px;
  font-weight: $fontWeightRegular;
}

.header-2 {
  font-size: 32px;
  margin-bottom: 40px;
}

.header-3 {
  font-size: 20px;
  margin-bottom: 16px;
}

.header-4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 18px;
}

.header-5 {
  font-size: 16px;
  font-weight: $fontWeightMedium;
  margin-top: 8px;
  margin-bottom: 8px;
}

.wrapper {
  position: relative;
}

.danger {
  color: #ff2f2f;
}

.MuiMenuItem-root.danger {
  color: #ff2f2f;
}

.--bk-white {
  background-color: white;
}

.primary-actions {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding-left: 32px;
  padding-right: 32px;

  .MuiTab-wrapper {
    text-transform: none;
  }

  .Mui-selected {
    .MuiTab-wrapper {
      font-weight: $fontWeightMedium;
    }
  }

  .PrivateTabIndicator-colorSecondary-3 {
    background-color: $ab-black;
  }

  .PrivateTabIndicator-root-1 {
    height: 3px;
  }
}

.table-wrapper {
  background-color: white;
  border-top: 1px solid $ab-greyl;
  padding-top: 24px;

  &--has-tabs {
    height: calc(100% - 64px);
  }

  &--nav-title {
    height: calc(100% - 84px);
  }

  &--nav-title-tabs {
    height: calc(100% - 104px);
  }

  &__filters {
    padding-left: 32px;
  }

  &--no-padding {
    padding-top: 0;
    border-top: 0px;
  }

  &__count {
    margin-top: 8px;
    margin-bottom: 16px;
    // Temp
    padding-left: 32px;
  }

  // Mobile
  .mobile-card {
  }
}

.bold {
  font-weight: $fontWeightBold;
}

.flow-root {
  display: flow-root;
}

.normal-weight {
  font-weight: normal;
}

.semibold {
  font-weight: $fontWeightMedium;
}

.host-color {
  color: $ab-host;
}

.housekeeper-color {
  color: $ab-housekeeper;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Delay cleaning modal
.table-cell--negative {
  margin-top: -16px;
  padding-top: 16px;
  margin-left: -16px;
  padding-left: 16px;
  margin-right: 16px;
  padding-right: 16px;
}

.agreement {
  margin-top: 24px;
  color: $ab-grey;
  font-size: 14px;

  &.agreement-host .agreement-important {
    color: $ab-host;
  }

  &.agreement-housekeeper .agreement-important {
    color: $ab-housekeeper;
  }
}

// SELECT
.MuiFormControl-root {
  .Mui-red-dot {
    display: block;
    position: absolute;
    top: -2px;
    right: -2px;
    width: 8px;
    height: 8px;
    background: $ab-danger;
    border-radius: 8px;
  }
}

// MATERIAL CSS
.list-subheader {
  display: block !important;
}
// Bold th
tbody .table-row--upcoming th.MuiTableCell-root {
  font-weight: 600;
}
.MuiTab-root {
  text-transform: inherit !important;
  font-size: 16px !important;
  font-weight: $fontWeightRegular !important;
}
.Mui-selected {
  .MuiTab-root {
    font-weight: $fontWeightMedium;
  }
}
.tableRow--clickable {
  cursor: pointer;
}

// Material UI Tabs
.MuiTabs-scroller
  .MuiTabs-flexContainerVertical
  .PrivateTabIndicator-colorSecondary-95 {
  background-color: $abPrimary;
}

.MuiTabs-vertical .MuiTab-root .MuiTab-wrapper {
  align-items: flex-start;
}

// Tabs indicator
.MuiTabs-root {
  .MuiTabs-indicator {
    height: 3px;
  }
}

.MuiButton-label {
  text-transform: none;
}

.MuiButton-root.MuiButton-contained {
  padding-top: 8px;
  padding-bottom: 8px;
}

.MuiButton-root.MuiButton-text {
  padding-top: 8px;
  padding-bottom: 8px;
}

// .navigation.MuiAppBar-colorPrimary {
//   background-color: white;
// }

.filters {
  margin-left: 24px;
}

// Padded select options and hover states for Check in/out dropdown.
.ab-select_options {
  padding: 3px;

  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
}

/*
||
|| ".pac-container" is a class for google autocomplete dropdown container
|| zIndex is set to render the autocomplete container above the property edit 
|| dialog. 
||
*/
.pac-container {
  z-index: 10001;
}

.host-primary {
  &.MuiButton-contained {
    background-color: $abPrimary !important;
    color: white !important;
  }

  &.MuiButton-outlined {
    border-color: $abPrimary !important;
    color: $abPrimary !important;
  }

  &.MuiButton-contained.Mui-disabled {
    background-color: rgba($abPrimary, 0.12) !important;
  }

  // &.MuiButton-root:hover {
  //   background-color: rgba($abPrimary, 0.12) !important;
  // }
}

.ops-primary {
  &.MuiButton-contained {
    background-color: #ffcc80;
    color: #212121;
  }

  &.MuiButton-contained:hover {
    background-color: rgba(254, 220, 170, 1);
  }
}

// Table
.host {
  .MuiTableRow-root.Mui-selected {
    background-color: rgba($abPrimary, 0.12);
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: $abPrimary !important;
  }

  .MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: rgba($abPrimary, 0.12);
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: rgba($abPrimary, 0.12);
  }

  // sidebar selected
  .PrivateTabIndicator-colorSecondary-49 {
    background-color: $abPrimary !important;
  }

  // Navigation
  &.MuiAppBar-root {
    background-color: $abPrimary !important;

    .PrivateTabIndicator-colorSecondary-49 {
      background-color: white !important;
    }

    .MuiButtonBase-root {
      text-transform: none;
    }
  }

  // Stepper
  .MuiStepIcon-root.MuiStepIcon-active {
    color: $abPrimary;
  }

  .MuiPaper-root {
    background-color: transparent;
  }

  // form
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $abPrimary;
  }
  .MuiFormLabel-root.Mui-focused {
    color: $abPrimary;
  }
}

// Typography MUI
.typography {
  &__subtitle2 {
    color: grey;
  }
}

.ab-custom-mui {
  .MuiInputBase-root .PrivateNotchedOutline-root-1 {
    top: 1px;
  }

  .MuiFormControl-root .MuiInputLabel-formControl {
    left: 14px;
  }
}

// spin-animation
.spin-animation {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

// RUN LOCAL CAMPAIGNS
.campaign {
  position: fixed;
  top: 20px;
  align-self: center;
  left: calc(50% - calc(348px / 2));
  background-color: $ab-black;
  padding: 4px;
  padding-left: 8px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px solid lighten($ab-black, 15%);
  transition: box-shadow 0.3s ease-in-out;

  width: 348px;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-name: tada;
  z-index: 1;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.6) 0px 10px 29px 0px;
  }

  &__emoji {
    background-color: lighten($ab-black, 10%);
    border-radius: 10px;
    width: 24px;
    height: 24px;
    padding: 10px;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    margin-right: 8px;
  }

  .button {
    margin-left: 0;
    padding: 0px;
    border: none;
    color: white;
    font-size: 14px;
  }

  &__text {
    color: white;
    font-size: 14px;
  }

  &__text--ops {
    color: #ffb84d;
  }

  .button-close {
    position: absolute;
    top: 4px;
    right: 6px;
    z-index: 1;
    padding: 0;
  }

  .button-typeform {
    color: white;
    text-align: left;
    font-weight: 400;
    line-height: 120%;
  }
}

.marketplace-tada {
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-name: tada;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 580px) {
  body,
  .main {
    background-color: white;
  }
  .marketplace-promo {
    max-width: 350px;
  }
  .card {
    padding: 16px;
  }

  .filters {
    margin-left: 8px;
  }

  .MuiButton-root.clear-all {
    display: block;
  }

  .header-1 {
    font-size: 32px;
  }

  .header-2 {
    font-size: 24px;
  }

  .header-3 {
    font-size: 16px;
  }

  .header-4 {
    font-size: 14px;
  }

  .primary-actions {
    padding-left: 12px;
  }

  .table-wrapper {
    &__filters {
      padding-left: 12px;
    }

    &__count {
      // Temp
      padding-left: 12px;
    }
  }

  .flex-direction-mobile {
    flex-direction: column;
  }

  .mt1-mobile {
    margin-top: 8px;
  }

  .stretch-children-mobile {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    & > * {
      flex-grow: 1;
    }
  }

  // Items count: Showing {#} of {#}
  .items-count {
    padding-left: 16px;
  }
}
