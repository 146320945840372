@import "../styles/var.scss";

.MuiButtonBase-root.filter {
  border: 1px solid #b1b1b1;
  color: #3e3e3e;
  border-radius: 200px;
  padding: 4px 8px;
  margin-right: 8px;
  font-size: 14px;

  &.active {
    border-width: 2px;
    border-color: black;
    color: black;
    font-weight: $fontWeightMedium;
  }
}

.filter__count {
  background-color: black;
  color: white;
  border-radius: 100px;
  width: 16px;
  height: 16px;
  line-height: 15px;
  font-size: 10px;
  margin-right: 4px;
}

.mobile-ui {
  .MuiButtonBase-root.filter {
    font-size: 16px;
  }
}
