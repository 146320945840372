.new-property {
  width: 100%;
  height: calc(100% - 48px);

  &__stepper {
    padding-top: 32px;
    width: 70%;
    margin: 0 auto;
  }

  .stepper__body {
  }

  .stepper__actions {
    margin-top: 32px;
    float: right;
    margin-bottom: 50px;
  }

  &__import-calendar {
    .cost {
      font-size: 24px;
    }
    .period {
      font-size: 14px;
    }
  }
}
