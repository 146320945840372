@import "../styles/var.scss";

.ab-public {
  // Resets
  .page-content {
    height: auto;
    max-width: calc(375px - 50px);
    border: 1px solid $ab-greyxl;
    min-height: auto;
    border-radius: 3px;
  }
  // if the page wrapper is of a type split than there is not padding.
  &.page-wrapper:not(.page-wrapper--split) {
    padding-top: 100px;
  }

  &__branding {
    text-align: center;
    margin-bottom: 24px;
    text-decoration: none;
    color: $ab-black;
  }

  &__branding-logo {
    width: 64px;
    margin-bottom: 8px;
  }
  &__branding-name {
    font-weight: 500;
    font-size: 16px;
  }

  &__pros-signup,
  &__password,
  &__login {
    padding: 24px;
    max-height: calc(100% - 48px) !important;
  }

  &__host-signup {
    display: inline-flex;
    justify-content: center;
    max-width: 450px;
    padding: 16px;

    .header-1 {
      margin-bottom: 4px;
    }
  }

  // Split brand
  .brand {
    position: relative;
    padding-left: 10%;
    padding-right: 4%;
    color: white;

    &__quote {
      position: absolute;
      top: -65px;
      left: 7%;
      font-size: 48px;
      // Flip the quote
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }

    &__title {
      font-weight: $fontWeightBold;
      font-size: 40px;
      margin-bottom: 24px;
      line-height: 45px;
      letter-spacing: 0.5px;
    }

    &__testimony {
      padding-left: 16px;
      font-size: 14px;
    }

    .testimony__user {
      margin-top: 16px;
      display: inline-flex;
    }

    .avatar {
      margin-right: 8px;
    }

    .user__name,
    .user__title {
      margin: 0;
      display: block;
    }

    .user__title {
      font-size: 14px;
      opacity: 0.7;
    }
  }

  .floating {
    padding-top: 50px;
    float: right;
    padding-right: 24px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

// Sign in screen has a logo therefore padding is reduced.
.ab-public.page-wrapper:not(.page-wrapper--split).ab-public--negative-space {
  padding-top: 64px;
}

@media only screen and (max-width: 800px) {
  .ab-public {
    .brand {
      max-width: 450px;
      padding-top: 24px;
      padding-bottom: 24px;
    }
    .brand__title {
      font-size: 24px;
    }

    .brand__quote {
      font-size: 32px;
      top: -40px;
      left: initial;
    }

    .brand__testimony {
      padding-left: 0;
    }

    &__host-signup {
      padding-bottom: 100px;
    }
  }
}
@media only screen and (max-width: 500px) {
  .ab-public {
    .floating {
      right: initial;
      text-align: center;
    }
  }
}
