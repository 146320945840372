.trial-banner {
  background: red;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .banner-content {
    color: white;
    font-size: 12px;
  }

  .banner-button {
    border: 1px solid white;
    font-size: 12px;
  }

  .banner-button.button.button-mini {
    padding: 6px 12px;
  }
}
