@import "../styles/var.scss";
.ops-table {
  .MuiTableCell-root.pl4 {
    padding-left: 32px;
  }
  &__flag.MuiChip-root {
    background-color: $ab-greyxl;
    color: black;
    border: 1px solid darken($ab-greyl, 10%);
  }

  &__icon--same-day.MuiChip-icon {
    color: $ab-danger;
  }

  &__status {
    .cleaning-status .status {
      font-size: 12px;
    }
  }
}

.ops-menu {
  $this: &;

  &__icon.MuiSvgIcon-root {
    margin-right: 8px;
    color: $ab-mgrey;
    width: 0.8em;
  }

  &__item--accepted.MuiMenuItem-root {
    color: $ab-success;
    font-weight: 500;

    #{$this}__icon {
      color: $ab-success;
    }
  }

  &__item--declined.MuiMenuItem-root {
    color: $ab-danger;
    font-weight: 500;

    #{$this}__icon {
      color: $ab-danger;
    }
  }
}
