@import "../styles/var.scss";

.navigation-wrapper.MuiAppBar-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .navigation-wrapper.navigation-wrapper--brand-ops.MuiAppBar-colorPrimary {
//   background-color: $ab-ops !important;
//   padding-top: 4px;

//   .MuiTab-root {
//     font-weight: normal !important;
//   }
// }

.navigation-wrapper--has-subnav.MuiAppBar-colorPrimary {
  height: 99px;
}

.navigation {
  display: inline-flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;

  max-width: 1278px;
  width: calc(100% - 48px);

  &__title,
  &__actions {
    display: inline-flex;
    align-items: center;
  }

  &__user .button {
    line-height: 21px;
    // Align it to the right
    padding-right: 0;
  }

  .button {
    color: white;
  }

  .button--menu {
    padding-right: 16px;
  }

  &__title .button {
    padding-left: 8px;
  }

  .button--small {
    margin-right: 0px;
    &.MuiButton-root {
      padding-left: 16px;
      padding-right: 16px;
      height: 36px;
      border-radius: 2px;
    }
    &.MuiButton-outlinedPrimary {
      border-color: white;
    }
  }

  .MuiTabs-indicator {
    background-color: white !important;
    bottom: 1px;
  }

  .logo {
    width: 40px;
    height: 40px;
    margin-right: 40px;
    cursor: pointer;

    img {
      width: 100%;
      display: block;
    }

    svg {
      margin-top: 4px;
    }
  }

  &--public {
    &.MuiAppBar-colorDefault {
      background-color: white;
    }
    .nav-action {
      color: #6059f7;
    }
  }

  &--mobile {
    padding-left: 8px;
    padding-right: 8px;

    .logo {
      margin-right: 8px;
    }
  }
}

.sub-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ab-greyxxl;
  color: $ab-black;
  box-shadow: 0px 2px 9px -3px $ab-greyl;
  z-index: 1;
  width: 100%;

  // Arrow button: Remove left padding to align it
  .button {
    padding-left: 0;
  }

  .title {
    font-weight: $fontWeightMedium;
  }

  &__content {
    padding-left: 24px;
    padding-right: 24px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 1278px;
    width: 100%;
  }
}

.navigation-drawer {
  min-width: 220px;
}

.mobile-nav__list {
  min-width: 230px;
  height: 100%;

  // List padding
  .MuiListItem-gutters {
    padding-left: 24px;
  }
}
