@import "../styles/var.scss";

.pms-dialog {
  &__body {
    min-width: 500px;
  }
  &__actions {
  }

  .actions {
    &--default {
      margin-right: 16px;
    }
  }

  &__title {
  }

  &__sub-title {
    color: gray;
    font-weight: $fontWeightRegular;
    letter-spacing: normal;
  }

  &--small {
    width: 400px;
  }
}

@media only screen and (max-height: 600px) {
  .promotion-dialog {
    .MuiDialog-paperWidthSm {
      margin: 8px 32px;
      max-height: calc(100% - 16px);
    }

    .MuiDialogActions-root {
      margin-top: 8px;
    }
  }
}
