@import "./var.scss";

.gxp-form {
  width: 80%;
  margin: 0 auto;
  background-color: white;
  padding: 16px;
  border-radius: 3px;
  border: 1px solid #d8d8e8;

  &--item {
    margin-bottom: 8px;
  }

  &--inline {
    width: 100%;
  }

  &--inline &--item {
    width: 50%;
  }

  &__checkbox {
    .subtext {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__time-picker {
    position: relative;
  }
}

$timePickerHeight: 250px;

.time-picker {
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgba(black, 0.8);
  }

  &__popup {
    position: fixed;
    bottom: 0 !important;
    top: initial !important;
    left: 0;
    right: 0;
    height: $timePickerHeight;
    z-index: 2;
    background: white;
  }

  &__popup .ant-time-picker-panel-inner {
    box-shadow: none;
    border-top: 1px solid rgba(black, 0.3);
  }

  &__popup .ant-time-picker-panel-input-wrap {
    width: 100%;
    max-width: 100%;
  }

  &__popup .ant-time-picker-panel-select {
    width: 50%;
  }

  &__popup .ant-time-picker-panel-select ul {
    width: 100%;
  }

  &__action {
    pointer-events: none;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: $timePickerHeight;
    left: 0;
    right: 0;
    z-index: 2;
    height: 48px;
    padding: 4px 16px;
    background: white;
    border-top: 1px solid rgba(black, 0.3);

    -webkit-box-shadow: 0px -4px 25px -13px rgba(64, 64, 64, 1);
    -moz-box-shadow: 0px -4px 25px -13px rgba(64, 64, 64, 1);
    box-shadow: 0px -4px 25px -13px rgba(64, 64, 64, 1);
  }
}

// Disabled
.gxp-form-diabled {
  .ant-input[disabled],
  .ant-time-picker-input[disabled] {
    background: white;
    color: black;
    border: none;
    font-size: 16px;
    cursor: auto;
  }

  .ant-form-item-label > label {
    color: gray;
    font-size: 13px;
    padding-left: 11px;
  }

  .ant-form-item-label > label:before {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  .gxp-form {
    width: 60%;
  }
}

// Older scss
.form-field {
  margin-bottom: 16px;

  .label {
    display: block;
    color: $formLabel;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: $fontWeightRegular;
  }

  .form-icon {
    position: absolute;
    top: 25px;
    right: 16px;
    line-height: 0;
    pointer-events: none;
  }

  .form-input {
    height: 48px;
    border: 1px solid #bdbdbd;
    font-size: 14px;
    // Padding right 8px + 2px (border left and right) = 10px
    width: calc(100% - 10px);
    outline: none;
    padding-left: 8px;
  }

  .form-input:focus {
    box-shadow: 0px 0px 4px -1px $ab-link;
    border-color: $ab-link;
  }

  .form-error {
    display: block;
    font-size: 12px;
    color: $ab-danger;
    margin-top: 2px;
  }

  &.error {
    position: relative;
  }

  &.error .form-error {
    position: absolute;
  }

  &.error .form-input {
    border-color: $ab-danger;
  }

  &.error .form-input:focus {
    box-shadow: 0px 0px 4px -1px $ab-danger;
  }
}

.form-select {
  font-size: 19px;
  line-height: 24px;
  padding-top: 11px;
  padding-bottom: 11px;
  color: #484848;
  font-weight: $fontWeightRegular;
  background-color: transparent !important;
  border: none;
  border-radius: 0px;
  padding-left: 11px;
  padding-right: 40px;
  height: 46px;
  display: block;
  width: 100%;
  outline: none;
  cursor: pointer;
  border: 1px solid #bdbdbd;
}

.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-header {
  margin-bottom: 4px;
  font-size: 24px;
}

.form-desc {
  font-size: 14px;
  color: $ab-grey;
  margin-bottom: 16px;
}

.form-button {
  margin-top: 16px;
}
